import classNames from "classnames"
import "./FormItem.scss"

export const FormItem = ({ className, label, children }) => {
  return (
    <div className={classNames(className, "form-item-wrapper")}>
      {label && <span>{label}</span>}

      {children}
    </div>
  )
}
