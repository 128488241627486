/* eslint-disable */

export default {
  English: 'en',
  'فارسی': 'ir',
  'हिन्दी': 'in',
  'नेपाली': 'ne',
  Português: 'pt',
  'ਪੰਜਾਬੀ': 'pa',
  Română: 'ro',
  Español: 'es',
  'اردو': 'ur',
  'Русский': 'ru',
  'తెలుగు': 'te',
  '中國人': 'cn',
}