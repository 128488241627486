import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

import { handleLogout } from '@store/authentication'

export const getStudentGroup = createAsyncThunk('group/getStudentGroup', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get(`api/groups/student-group`, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const getGroupsByLeader = createAsyncThunk('group/getGroupsByLeader', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get(`/api/groups/by-leader/${params.leaderId}`)

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

const initialState = {
  studentGroup: null,
  loadingStudentGroup: true,

  groupsByLeader: [],
  isGroupsByLeaderLoading: true
}

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getStudentGroup.fulfilled, (state, action) => {
        state.studentGroup = action.payload
        state.loadingStudentGroup = false
      })
      .addCase(getStudentGroup.rejected, (state, action) => {
        state.studentGroup = action.payload
        state.loadingStudentGroup = false
      })
      .addCase(getGroupsByLeader.fulfilled, (state, action) => {
        action.payload.forEach(result => state.groupsByLeader.push(result))
        state.isGroupsByLeaderLoading = false
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  }
})

export default groupSlice.reducer