import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

import { handleLogout } from '@store/authentication'

export const getStudentCertificates = createAsyncThunk('certificate/getStudentCertificates', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get('/api/student-certificates', {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const getNetworkLeaderCertificates = createAsyncThunk('certificate/getNetworkLeaderCertificates', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get('/api/network-leader-certificates', {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const getAdminCertificates = createAsyncThunk('certificate/getAdminCertificates', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get('/api/admin-certificates', {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

const initialState = {
  loadingStudentCertificates: true, 
  studentCertificates: [],

  loadingNLCertificates: true,
  networkLeaderCertificates: [],

  loadingAdminCertificates: true,
  adminCertificates: [],
}

const certificateSlice = createSlice({
  name: 'certificate',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getStudentCertificates.fulfilled, (state, action) => {
        state.studentCertificates = action.payload
        state.loadingStudentCertificates = false
      })
      .addCase(getStudentCertificates.rejected, (state, action) => {
        state.studentCertificates = action.payload
        state.loadingStudentCertificates = false
      })
      .addCase(getNetworkLeaderCertificates.fulfilled, (state, action) => {
        state.networkLeaderCertificates = action.payload
        state.loadingNLCertificates = false
      })
      .addCase(getNetworkLeaderCertificates.rejected, (state, action) => {
        state.networkLeaderCertificates = action.payload
        state.loadingNLCertificates = false
      })
      .addCase(getAdminCertificates.fulfilled, (state, action) => {
        state.adminCertificates = action.payload
        state.loadingAdminCertificates = false
      })
      .addCase(getAdminCertificates.rejected, (state, action) => {
        state.adminCertificates = action.payload
        state.loadingAdminCertificates = false
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  }
})

export default certificateSlice.reducer
