import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Cookies from 'universal-cookie'

import { RTL_LANGUAGES } from '@enum'

const cookies = new Cookies()

export const changeLanguage = createAsyncThunk('language/changeLanguage', async (params, thunkAPI) => {
  try {
    cookies.set('currentLanguage', params.language, { path: '/' })
    return params.language
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

const initialState = {
  currentLanguage: cookies.get('currentLanguage') || 'en',
  isRTLLanguage: false,
}

const languageSlice = createSlice({
  name: 'language',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(changeLanguage.fulfilled, (state, action) => {
        state.currentLanguage = action.payload
        state.isRTLLanguage = RTL_LANGUAGES.includes(action.payload)
      })
  }
})
export default languageSlice.reducer