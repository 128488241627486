import { Input as OldInput } from "reactstrap"
import classNames from "classnames"
import "./Input.scss"

export const Input = ({
  placeholder,
  className,
  inputClassName,
  prefix,
  type,
  id,
  error,
  onChange,
  ...props
}) => {
  return (
    <div className={classNames(className && className, "input-wrapper")}>
      {prefix && <div className={classNames("prefix")}>{prefix}</div>}

      <OldInput
        placeholder={placeholder}
        onChange={onChange}
        className={classNames(inputClassName && inputClassName)}
        type={type}
        id={id}
        {...props}
      />

    {error && <span className="error">{error}</span>}
    </div>
  )
}
