import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { AUTH_LEVEL } from '@src/enum'
import { handleLogout } from '@store/authentication'
import { getStudentsOverview, STUDENTS_PER_PAGE } from './groupLeader'

export const getGroupLeaders = createAsyncThunk('user/getGroupLeaders', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get(`/api/users/group-leaders`, {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const getNetworkLeaders = createAsyncThunk('user/getNetworkLeaders', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get(`/api/users/by-leader/${params.leaderId}?role=2`)

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const createLeader = createAsyncThunk('user/createLeader', async (params, thunkAPI) => {
  try {
    const { data, status } = await axios.post(
      `/api/users/invite`,
      {
        firstName: params.firstName,
        lastName: params.lastName,
        email: params.email,
        role: params.role,
        leader: params.leader,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )

    if (status === 200) {
      if (params.role === 'group-leader') {
        await thunkAPI.dispatch(getGroupLeaders(params))
      }
      
      if (params.role === 'network-leader') {
        await thunkAPI.dispatch(getNetworkLeaders({...params, leaderId: params.currentUserId}))
      }
    }

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const getUsersByLeader = createAsyncThunk('user/getUsersByLeader', async (params, thunkAPI) => {
  try {
    let url = `/api/users/by-leader/${params.leaderId}`
    if (params.role && params.role !== -1) {
      url = `${url}?role=${params.role}`

      if (params.search) {
        url = `${url}&search=${params.search}`
      }
    } else if (params.search) {
      url = `${url}?search=${params.search}`
    }
    const { data } = await axios.get(url)

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const getLeadersByUser = createAsyncThunk('user/getLeaders', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get(`/api/users/leaders`)

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const assignUserRole = createAsyncThunk('user/assignUserRole', async (params, thunkAPI) => {
  try {
    const { data } = await axios.post(
      `/api/users/assign-role`,
      {
       role: params.role,
       userId: params.id       
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const deleteUser = createAsyncThunk('user/deleteUser', async (params, thunkAPI) => {
  try {
    const response = await axios.delete(
      `/api/users/${params.id}`,   
      {
        data: {
          pending: params.pending
        } 
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )
    if (response.status === 200) {
      if (params.role === AUTH_LEVEL.STUDENT) {
        thunkAPI.dispatch(
          getStudentsOverview({
            accessToken: params.accessToken,
            offset: 0,
            limit: STUDENTS_PER_PAGE
          }),
        )
      } else if (params.role === AUTH_LEVEL.GROUP_LEADER) {
        thunkAPI.dispatch(
          getGroupLeaders({
            accessToken: params.accessToken
          })
        )
      } else if (params.role === AUTH_LEVEL.NETWORK_LEADER) {
        thunkAPI.dispatch(
          getNetworkLeaders({
            leaderId: params.leaderId
          })
        )
      }
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})


const initialState = {
  createLeaderDetails: {},

  userLeaders: [],

  groupLeaders: [],
  isGroupLeadersLoading: true,

  usersByLeader: {},
  loadingUserByLeader: true,

  dataNetworkLeaders: [],
  loadingNetworkLeaders: true,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetCreateLeaderDetails(state) {
      state.createLeaderDetails = {}
    },
    resetUsersByLeader(state) {
      state.usersByLeader = {}
      state.loadingUserByLeader = true
    },
    resetgetNetworkLeaders(state) {
      state.loadingNetworkLeaders = true
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createLeader.fulfilled, (state, action) => {
        state.createLeaderDetails = action.payload
      })
      .addCase(createLeader.rejected, (state, action) => {
        state.createLeaderDetails = action.payload
      })
      .addCase(getGroupLeaders.fulfilled, (state, action) => {
        state.isGroupLeadersLoading = false
        state.groupLeaders = action.payload
      })
      .addCase(getGroupLeaders.rejected, (state, action) => {
        state.isGroupLeadersLoading = false
        state.groupLeaders = action.payload
      })
      .addCase(getUsersByLeader.fulfilled, (state, action) => {
        const { role } = action.meta.arg
        switch (role) {
          case 2:
            if (state.usersByLeader['network-leaders'] === undefined && action.payload.length !== 0) {
              state.usersByLeader['network-leaders'] = action.payload
            } else if (action.payload.length !== 0) {
              action.payload.forEach(user => {
                const userAlreadyAdded = state.usersByLeader['network-leaders'].findIndex(u => u.id === user.id) !== -1
                if (!userAlreadyAdded) {
                  state.usersByLeader['network-leaders'].push(user)
                }
              })
            }
            break
          case 1:
              if (state.usersByLeader['group-leaders'] === undefined && action.payload.length !== 0) {
                state.usersByLeader['group-leaders'] = action.payload
              } else if (action.payload.length !== 0) {
                action.payload.forEach(user => {
                  const userAlreadyAdded = state.usersByLeader['group-leaders'].findIndex(u => u.id === user.id) !== -1
                  if (!userAlreadyAdded) {
                    state.usersByLeader['group-leaders'].push(user)
                  }
                })
              }
              break
          case 0:
              if (state.usersByLeader['students'] === undefined && action.payload.length !== 0) {
                state.usersByLeader['students'] = action.payload
              } else if (action.payload.length !== 0) {
                action.payload.forEach(user => {
                  const userAlreadyAdded = state.usersByLeader['students'].findIndex(u => u.id === user.id) !== -1
                  if (!userAlreadyAdded) {
                    state.usersByLeader['students'].push(user)
                  }
                })
              }
              break
          case -1:
            state.usersByLeader['all-users'] = action.payload
            break
          default:
            console.log('role', role)

        }
        state.loadingUserByLeader = false
      })
      .addCase(getNetworkLeaders.fulfilled, (state, action) => {
        state.dataNetworkLeaders = action.payload
        state.loadingNetworkLeaders = false
      })
      .addCase(getNetworkLeaders.rejected, (state, action) => {
        state.dataNetworkLeaders = action.payload
      })
      .addCase(getLeadersByUser.fulfilled, (state, action) => {
        state.userLeaders = action.payload.sort((a, b) => (a.authLevel < b.authLevel ? 1 : -1))
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  },
})

export const { resetCreateLeaderDetails, resetUsersByLeader, resetgetNetworkLeaders } = userSlice.actions
export default userSlice.reducer
