import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { handleLogout } from '@store/authentication'

const TOPICS_PER_PAGE = 10
const COMMENTS_PER_PAGE = 10

export const getAllTopics = createAsyncThunk('forum/getTopics', async (params, thunkAPI) => {
  try {
    let url = `/api/topics/all-topics?limit=${params.limit}&offset=${params.offset}`
    if (params.selectedGroup) {
      url = `${url}&selectedGroup=${params.selectedGroup}`
    }
    if (params.search) {
      url = `${url}&search=${params.search}`
    }
    const response = await axios.get(url, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const createTopic = createAsyncThunk('forum/createTopic', async (params, thunkAPI) => {
  try {
    const requestBody = {
      title: params.title,
      content: params.content,
      groups: params.groups,
    }
    if (params.lessonId) {
      requestBody.lessonId = params.lessonId
    }
    const response = await axios.post(`/api/topics`, requestBody, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 201) {
      await thunkAPI.dispatch(getAllTopics({
        accessToken: params.accessToken,
        limit: 2,
        offset: 0
      }))
    }
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const editTopic = createAsyncThunk('forum/editTopic', async (params, thunkAPI) => {
  try {
    const response = await axios.put(
      `/api/topics/${params.id}`,
      {
        title: params.title,
        content: params.content,
        groups: params.groups,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getTopicsByLessonId = createAsyncThunk('forum/getTopicsByLessonId', async (params, thunkAPI) => {
  try {
    let url = `/api/topics/lessons/${params.lessonId}/all-topics?limit=${params.limit}&offset=${params.offset}`
    if (params.myTopics) {
      url = `${url}&myTopics=true`
    }
    if (params.search) {
      url = `${url}&search=${params.search}`
    }
    const response = await axios.get(url, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const deleteTopic = createAsyncThunk('forum/deleteTopic', async (params, thunkAPI) => {
  try {
    const response = await axios.delete(`/api/topics/${params.id}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 200) {
      params.limit = TOPICS_PER_PAGE
      params.offset = 0
      if (params.lessonId) {
        await thunkAPI.dispatch(getTopicsByLessonId(params))
      } else {
        await thunkAPI.dispatch(getAllTopics(params))
      }
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getMyTopics = createAsyncThunk('forum/getMyTopics', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/topics/my-topics?limit=${params.limit}&offset=${params.offset}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getTopic = createAsyncThunk('forum/getTopic', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/topics/${params.id}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const reopenTopic = createAsyncThunk('forum/reopenTopic', async (params, thunkAPI) => {
  try {
    const response = await axios.put(`/api/topics/${params.id}/reopen-topic`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 201) {
      params.limit = TOPICS_PER_PAGE
      params.offset = 0
      if (params.lessonId) {
        await thunkAPI.dispatch(getTopicsByLessonId(params))
      } else {
        await thunkAPI.dispatch(getAllTopics(params))
      }
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const closeTopic = createAsyncThunk('forum/reopenTopic', async (params, thunkAPI) => {
  try {
    const response = await axios.put(`/api/topics/${params.id}/close-topic`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 201) {
      params.limit = TOPICS_PER_PAGE
      params.offset = 0
      if (params.lessonId) {
        await thunkAPI.dispatch(getTopicsByLessonId(params))
      } else {
        await thunkAPI.dispatch(getAllTopics(params))
      }
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getPendingTopics = createAsyncThunk('forum/getPendingTopics', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/topics/pending-topics?limit=${params.limit}&offset=${params.offset}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getPendingTopicsCount = createAsyncThunk('forum/getPendingTopicsCount', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/topics/pending-topics-count`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data.total
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const rejectTopic = createAsyncThunk('forum/rejectTopic', async (params, thunkAPI) => {
  try {
    const response = await axios.delete(`/api/topics/${params.id}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 200) {
      params.limit = TOPICS_PER_PAGE
      params.offset = 0
      await thunkAPI.dispatch(getPendingTopics(params))
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const activateTopic = createAsyncThunk('forum/activateTopic', async (params, thunkAPI) => {
  try {
    const response = await axios.put(`/api/topics/${params.id}/activate-topic`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 201) {
      params.limit = TOPICS_PER_PAGE
      params.offset = 0
      await thunkAPI.dispatch(getPendingTopics(params))
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getLatestTopics = createAsyncThunk('forum/getLatestTopics', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/topics/all-topics?limit=${params.limit}&offset=${params.offset}&active=true`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getCommentsByTopicId = createAsyncThunk('forum/getCommentsByTopicId', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/comments/topic/${params.id}?limit=${params.limit}&offset=${params.offset}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const createComment = createAsyncThunk('forum/createComment', async (params, thunkAPI) => {
  const requestBody = {
    content: params.content,
    topicId: params.id,
  }
  if (params.commentId) {
    requestBody.commentId = params.commentId
  }
  try {
    const response = await axios.post(`/api/comments`, requestBody, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 201) {
      params.limit = COMMENTS_PER_PAGE
      params.offset = 0
      await thunkAPI.dispatch(getCommentsByTopicId(params))
      await thunkAPI.dispatch(getTopic(params))
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const editComment = createAsyncThunk('forum/editComment', async (params, thunkAPI) => {
  const requestBody = {
    content: params.content,
    topicId: params.id,
  }
  if (params.commentId) {
    requestBody.commentId = params.commentId
  }
  try {
    const response = await axios.put(`/api/comments/${params.commentId}`, requestBody, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 201) {
      params.limit = COMMENTS_PER_PAGE
      params.offset = 0
      await thunkAPI.dispatch(getCommentsByTopicId(params))
      await thunkAPI.dispatch(getTopic(params))
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const deleteComment = createAsyncThunk('forum/deleteComment', async (params, thunkAPI) => {
  try {
    const response = await axios.delete(`/api/comments/${params.commentId}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 200) {
      params.limit = COMMENTS_PER_PAGE
      params.offset = 0
      await thunkAPI.dispatch(getCommentsByTopicId(params))
      await thunkAPI.dispatch(getTopic(params))
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const likeComment = createAsyncThunk('forum/likeComment', async (params, thunkAPI) => {
  try {
    const response = await axios.put(`/api/comments/${params.commentId}/like`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 201) {
      params.limit = COMMENTS_PER_PAGE
      params.offset = 0
      await thunkAPI.dispatch(getCommentsByTopicId(params))
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const dislikeComment = createAsyncThunk('forum/dislikeComment', async (params, thunkAPI) => {
  try {
    const response = await axios.put(`/api/comments/${params.commentId}/dislike`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 201) {
      params.limit = COMMENTS_PER_PAGE
      params.offset = 0
      await thunkAPI.dispatch(getCommentsByTopicId(params))
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const deleteFile = createAsyncThunk('forum/deleteFile', async (params, thunkAPI) => {
  try {
    const response = await axios.delete(`/api/topics/${params.id}/delete-file`, {
      data: {
        id: params.publicId,
      },
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 201) {
      return {
        publicId: params.publicId,
      }
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const uploadFiles = createAsyncThunk('forum/uploadFiles', async (params, thunkAPI) => {
  try {
    const fd = new FormData()
    params.files.forEach(file => {
      fd.append('file', file)
    })
    const response = await axios.post(`/api/topics/${params.id}/upload-files`, fd, {
      headers: {
        Authorization: params.accessToken,
        'Content-Type': 'multipart/form-data',
      },
    })
    if (response.status === 201) {
      await thunkAPI.dispatch(getTopic(params))
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

const initialState = {
  topicOverview: {},
  isTopicOverviewLoading: true,

  topics: [],
  isTopicsLoading: true,

  errorLatestTopics: {},
  latestTopics: [],
  isLatestTopicsLoading: true,

  pendingTopics: [],
  isPendingTopicsLoading: true,

  topicComments: [],
  isTopicCommentsLoading: true,

  lessonTopics: [],
  isLessonTopicsLoading: true,

  myTopics: [],
  isMyTopicsLoading: true,

  pendingTopicsCount: [],

  topicsPagination: {
    offset: 0,
    limit: TOPICS_PER_PAGE,
    currentPage: 0
  },

  commentsPagination: {
    offset: 0,
    limit: COMMENTS_PER_PAGE,
    currentPage: 0
  },


}

const forumSlice = createSlice({
  name: 'forum',
  initialState,
  reducers: {
    setCurrentTopicsPaginationPage(state, action) {
      state.topicsPagination.currentPage = action.payload
    },
    setCurrentCommentsPaginationPage(state, action) {
      state.topicsPagination.currentPage = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllTopics.fulfilled, (state, action) => {
        state.isTopicsLoading = false
        state.topics = action.payload
      })
      .addCase(getLatestTopics.fulfilled, (state, action) => {
        state.isLatestTopicsLoading = false
        state.latestTopics = action.payload.records
      })
      .addCase(getPendingTopics.fulfilled, (state, action) => {
        state.isPendingTopicsLoading = false
        state.pendingTopics = action.payload
      })
      .addCase(getTopic.fulfilled, (state, action) => {
        state.isTopicOverviewLoading = false
        state.topicOverview = action.payload
      })
      .addCase(getCommentsByTopicId.fulfilled, (state, action) => {
        state.isTopicCommentsLoading = false
        state.topicComments = action.payload
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        const topicDetails = state.topicOverview
        const newFiles = state.topicOverview.topic.files.filter(file => file.publicId !== action.payload.publicId)
        topicDetails.topic.files = newFiles
        state.topicOverview = topicDetails
      })
      .addCase(getTopicsByLessonId.fulfilled, (state, action) => {
        state.isLessonTopicsLoading = false
        state.lessonTopics = action.payload
      })
      .addCase(getMyTopics.fulfilled, (state, action) => {
        state.isMyTopicsLoading = false
        state.myTopics = action.payload
      })
      .addCase(getPendingTopicsCount.fulfilled, (state, action) => {
        state.pendingTopicsCount = action.payload
      })
      .addCase(deleteTopic.fulfilled, state => {
        state.topicsPagination.offset = 0
        state.topicsPagination.currentPage = 0
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  },
})

export const {
  setCurrentTopicsPaginationPage,
  setCurrentCommentsPaginationPage
} = forumSlice.actions

export default forumSlice.reducer
