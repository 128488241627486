import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

import { handleLogout } from '@store/authentication'

export const getMyEvents = createAsyncThunk(
  "events/getMyEvents",
  async (params, thunkAPI) => {
    try {
      const { data } = await axios.get(`/api/events/my-events/all`, {
        headers: {
          Authorization: params.accessToken
        }
      })

      return data
    } catch (error) {
      const { errors } = error.response.data
      return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
    }
  }
)

export const createEvent = createAsyncThunk(
  "events/createEvent",
  async (params, thunkAPI) => {
    try {
      const { data } = await axios.post(`/api/events`, {
        name: params.name,
        startDate: params.startDate,
        endDate: params.endDate,
        description: params?.description,
        users: params.users,
        dateOffset: new Date().getTimezoneOffset(),
      }, {
        headers: {
          Authorization: params.accessToken
        }
      })

      return data
    } catch (error) {
      const { errors } = error.response.data
      return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
    }
  }
)

export const updateEvent = createAsyncThunk(
  "events/updateEvent",
  async (params, thunkAPI) => {
    try {
      const { data } = await axios.put(`/api/events/${params.id}`, {
        name: params.name,
        startDate: params.startDate,
        endDate: params.endDate,
        description: params?.description,
        users: params.users,
        dateOffset: new Date().getTimezoneOffset(),
      }, {
        headers: {
          Authorization: params.accessToken
        }
      })

      return data
    } catch (error) {
      const { errors } = error.response.data
      return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
    }
  }
)

export const deleteEvent = createAsyncThunk(
  "events/deleteEvent",
  async (params, thunkAPI) => {
    try {
      const { data } = await axios.delete(`/api/events/${params.id}`, {
        headers: {
          Authorization: params.accessToken
        }
      })

      return data
    } catch (error) {
      const { errors } = error.response.data
      return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
    }
  }
)

const initialState = {
  events: [],
  createEventDetails: null,
  updateEventDetails: null
}

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    resetCreateEvent(state) {
      state.createEventDetails = null
    },
    resetUpdateEvent(state) {
      state.updateEventDetails = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getMyEvents.fulfilled, (state, action) => {
        state.events = action.payload
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.createEventDetails = action.payload
        state.events = [...state.events, action.payload]
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        const { id: eventId } = action.meta.arg
        state.updateEventDetails = action.payload
        state.events = [...state.events.filter(event => event.id !== eventId), action.payload]
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        const { id: eventId } = action.meta.arg
        state.events = [...state.events.filter(event => event.id !== eventId)]
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  }
})

export const { resetCreateEvent, resetUpdateEvent } = eventsSlice.actions
export default eventsSlice.reducer