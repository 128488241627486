import classNames from "classnames"
import { Progress } from 'reactstrap'
import './ModuleProgress.scss'

export const ModuleProgress = ({ className, title, progress }) => {
  return (
    <div className={classNames(className, 'module__progress-wrapper')}>
      {title && <span className="title">{title}</span>}

      <div className="progress-data">
        <span className="value">{progress}%</span>

        <Progress value={progress} className="progress-bar-success" />
      </div>
    </div>
  )
}
