import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

import { handleLogout } from '@store/authentication'

export const getLessonsByModuleId = createAsyncThunk('lessons/getLessonsByModuleId', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/lessons/module/${ params.moduleId }`, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return response.data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const getLessonById = createAsyncThunk('lessons/getLessonById', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/lessons/${ params.lessonId }`, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return response.data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const createLesson = createAsyncThunk('lessons/createLesson', async (params, thunkAPI) => {
  const formData = new FormData()
  formData.append('moduleId', params.moduleId)
  formData.append('title', params.title)
  formData.append('content', params.content)
  formData.append('devotions', params.devotions)
  formData.append('videoUrl', params.videoUrl)
  formData.append('audioUrl', params.audioUrl)
  formData.append('questions', JSON.stringify(params.questions))
  formData.append("translations", JSON.stringify(params.translations) || null)

  
  try {
    const { data } = await axios.post(`/api/lessons`, formData, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const updateLesson = createAsyncThunk('lessons/updateLesson', async (params, thunkAPI) => {
  const body = {
    title: params.title,
    content: params.content,
    devotions: params.devotions,
    videoUrl: params.videoUrl,
    audioUrl: params.audioUrl,
    questions: JSON.stringify(params.questions),
    translations: JSON.stringify(params.translations)
  }
  
  try {
    const { data } = await axios.put(`/api/lessons/${params.lessonId}`, body, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const deleteLesson = createAsyncThunk('lessons/deleteLesson', async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(`/api/lessons/${params.lessonId}`, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

const initialState = {
  lesson: null,
  isLessonLoading: true,

  lessons: [],
  isLessonsLoading: true,

  createLessonDetails: null,

  updateLessonDetails: null
}

const lessonsSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    resetLesson(state) {
      state.lesson = null
      state.isLessonLoading = true
    },
    resetCreateLessonDetails(state) {
      state.createLessonDetails = null
    },
    resetUpdateLessonDetails(state) {
      state.updateLessonDetails = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getLessonsByModuleId.fulfilled, (state, action) => {
        state.lessons = action.payload
        state.isLessonsLoading = false
      })
      .addCase(getLessonById.fulfilled, (state, action) => {
        state.lesson = action.payload
        state.isLessonLoading = false
      })
      .addCase(createLesson.fulfilled, (state, action) => {
        state.createLessonDetails = action.payload
        state.lessons = [...state.lessons, action.payload]
      })
      .addCase(updateLesson.fulfilled, (state, action) => {
        state.updateLessonDetails = action.payload
        state.lesson = action.payload
      })
      .addCase(deleteLesson.fulfilled, (state, action) => {
        const { lessonId } = action.meta.arg
        state.lessons = state.lessons.filter(lesson => lesson.id !== lessonId)
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  }
})

export default lessonsSlice.reducer
export const { resetLesson, resetCreateLessonDetails, resetUpdateLessonDetails } = lessonsSlice.actions