import classnames from 'classnames'
import { Button as OldButton } from 'reactstrap'
import { LoadingSpinner } from '@src/common'
import './Button.scss'

export const Button = ({
  id,
  htmlType = 'button',
  className = '',
  // default height of the button will be 40px
  size = 20 | 40 | 55,
  onClick,
  loading,
  disabled,
  hiddenDisabled,
  prefix,
  suffix,
  // tooltip props
  color = 'outline-secondary' | 'secondary' | 'transparent' | 'danger',
  onMouseEnter,
  onMouseLeave,
  onFocus,
  ...props
}) => {
  return (
    <OldButton
      id={id}
      form={props.form}
      color={color}
      className={classnames(
        className,
        disabled && 'dbi-disabled',
        prefix && 'dbi-prefix',
        suffix && 'dbi-suffix',
        'dbi-button',
        `size__${size}`,
        `color__${color}`,
      )}
      type={hiddenDisabled || disabled ? 'button' : htmlType}
      disabled={hiddenDisabled || disabled || loading}
      {...(!loading && !disabled && !hiddenDisabled ? { onClick } : {})}
      {...{ onMouseEnter, onMouseLeave, onFocus }}
      {...props}
    >
      {prefix && !loading ? prefix : loading && <LoadingSpinner className={`button-loader ${color}`} />}

      {props.children}
    </OldButton>
  )
}
