import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

import { handleLogout } from '@store/authentication'

export const getStudentProgress = createAsyncThunk('progress/getStudentProgress', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get(`/api/progress`, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const completeLesson = createAsyncThunk('progress/completeLesson', async (params, thunkAPI) => {
  try {
    const { data } = await axios.post(`/api/progress/modules/${params.moduleId}/complete-lesson/${params.lessonId}`, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

const initialState = {
  studentProgress: []
}

const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    resetProgress(state) {
      state.studentProgress = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getStudentProgress.fulfilled, (state, action) => {
        state.studentProgress = action.payload
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  }
})
export const { resetProgress } = progressSlice.actions
export default progressSlice.reducer