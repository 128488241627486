import classNames from 'classnames'
import './LoadingSpinner.scss'

export const LoadingSpinner = ({ className, color, hasLoadingText }) => {
  return (
    <div className={classNames(className, 'loading-wrapper')}>
      <div className={classNames('loader', color && `${color}__loader`)} />

      {hasLoadingText && <span>Loading...</span>}
    </div>
  )
}
