import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { handleLogout } from '@store/authentication'

export const sendReminderToUsers = createAsyncThunk('reminder/sendReminderToUsers', async (params, thunkAPI) => {
  try {
    const { data } = await axios.post(`/api/reminders/users`, {
        message: params.message,
        subject: params.subject,
        users: params.users,
      }, {
        headers: {
          Authorization: params.accessToken,
        }
      }
    )

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

const initialState = {
  sendReminderToUsersDetails: {}
}

const reminderSlice = createSlice({
  name: 'reminder',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(sendReminderToUsers.fulfilled, (state, action) => {
        state.sendReminderToUsersDetails = action.payload
      })
      .addCase(sendReminderToUsers.rejected, (state, action) => {
        state.sendReminderToUsersDetails = action.payload
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  }
})

export default reminderSlice.reducer