import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { completeLesson } from './progress'

import { handleLogout } from '@store/authentication'

export const submitAssignments = createAsyncThunk(
  "assignments/submitAssignments", 
  async (params, thunkAPI) => {
    const { payload } = await thunkAPI.dispatch(completeLesson({ accessToken: params.accessToken, lessonId: params.lessonId, moduleId: params.moduleId }))

    if (!payload?.error) {
      try {
        const response = await axios.post("/api/assignments", 
        { lesson: params.lessonId, groupLeader: params.groupLeader, answers: params.answers },
        {
          headers: {
            Authorization: params.accessToken
          }
        })
  
        return response.data
      } catch (error) {
        const { errors } = error.response.data
        return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
      }
    }

    return thunkAPI.rejectWithValue({ error: payload?.error })
  }
)

export const submitAssignmentsFeedback = createAsyncThunk(
  'assignments/submitAssignmentsFeedback',
  async (params, thunkAPI) => {
    try {
      const response = await axios.post(
        `/api/assignments/${params.id}/feedback`,
        {
          feedback: params.feedback,
        },
        {
          headers: {
            Authorization: params.accessToken,
          },
        },
      )

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message })
    }
  },
)

export const submitModuleAssignmentFeedback = createAsyncThunk(
  'assignments/submitModuleAssignmentFeedback',
  async (params, thunkAPI) => {
    try {
      const response = await axios.post(
        `/api/module-assignments/${params.id}/feedback`,
        {
          feedback: params.feedback,
        },
        {
          headers: {
            Authorization: params.accessToken,
          },
        },
      )

      return response.data
    } catch (error) {
      const { errors } = error.response.data
      return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
    }
  },
)

export const getAssignmentsGL = createAsyncThunk('assignments/getAssignmentsGroupLeader', async (params, thunkAPI) => {
  try {
    const response = await axios.get('/api/assignments/group-leader', {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getAssignmentsStudent = createAsyncThunk('assignments/getAssignmentsStudent', async (params, thunkAPI) => {
  try {
    const response = await axios.get('/api/assignments/student', {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return response.data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const getModuleAssignmentsStudent = createAsyncThunk(
  'assignments/getModuleAssignmentsStudent',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`/api/module-assignments/${params.moduleId}/student`, {
        headers: {
          Authorization: params.accessToken,
        },
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message })
    }
  },
)

const initialState = {
  loadingSubmitAssignments: true,
  loadingSubmitAssignmentsFeedback: true,
  loadingSubmitModuleAssignmentsFeedback: true,
  loadingAssignmentsGL: true,
  dataAssignmentsGL: [],
  loadingAssignmentsStudent: true,
  dataAssignmentsStudent: [],
  loadingModuleAssignmentsStudent: true,
  moduleAssignmentsStudent: null,
  submitAssignmentsDetails: null,
}

const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState,
  reducers: {
    resetSubmitAssignmentsDetails(state) {
      state.submitAssignmentsDetails = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(submitAssignments.fulfilled, (state, action) => {
        state.resetSubmitAssignmentsDetails = null
        state.loadingSubmitAssignments = false
        if (state.dataAssignmentsStudent.error) {
          state.dataAssignmentsStudent = [action.payload]
        } else {
          state.dataAssignmentsStudent.push(action.payload)
        }
      })
      .addCase(submitAssignments.rejected, (state, action) => {
        state.submitAssignmentsDetails = action.payload
      })
      .addCase(submitAssignmentsFeedback.fulfilled, state => {
        state.loadingSubmitAssignmentsFeedback = false
      })
      .addCase(getAssignmentsGL.fulfilled, (state, action) => {
        state.loadingAssignmentsGL = false
        state.dataAssignmentsGL = action.payload
      })
      .addCase(getAssignmentsStudent.fulfilled, (state, action) => {
        state.loadingAssignmentsStudent = false
        state.dataAssignmentsStudent = action.payload
      })
      .addCase(getModuleAssignmentsStudent.fulfilled, (state, action) => {
        state.moduleAssignmentsStudent = action.payload
        state.loadingModuleAssignmentsStudent = false
      })
      .addCase(submitModuleAssignmentFeedback.fulfilled, state => {
        state.loadingSubmitModuleAssignmentsFeedback = false
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  },
})

export const { resetSubmitAssignmentsDetails } = assignmentsSlice.actions
export default assignmentsSlice.reducer
