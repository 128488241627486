import axios from "axios"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { handleLogout } from '@store/authentication'

export const getAllTeachers = createAsyncThunk('teacher/getAllTeachers', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get(`/api/teachers`, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const getTeachersByModule = createAsyncThunk('teacher/getTeachersByModule', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get(`/api/teachers/module/${params.moduleId}`, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const createTeacher = createAsyncThunk('teacher/createTeacher', async (params, thunkAPI) => {
  const formData = new FormData()
  formData.append('moduleId', params.moduleId)
  formData.append('name', params.name)
  formData.append('description', params.description)
  formData.append('image', params.file)
  formData.append("translations", JSON.stringify(params.translations) || null)


  try {
    const { data } = await axios.post(`/api/teachers`, formData, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const updateTeacher = createAsyncThunk('teacher/updateTeacher', async (params, thunkAPI) => {
  const formData = new FormData()
  formData.append('moduleId', params.moduleId)
  formData.append('name', params.name)
  formData.append('email', params.email)
  formData.append('description', params.description)
  formData.append('facebook', params.facebook)
  formData.append('twitter', params.twitter)
  formData.append('linkedin', params.linkedin)
  formData.append('image', params.file)
  formData.append("translations", JSON.stringify(params.translations) || null)

  try {
    const { data } = await axios.put(`/api/teachers/${params.teacherId}`, formData, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const deleteTeacher = createAsyncThunk('teacher/deleteTeacher', async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(`/api/teachers/${params.teacherId}`, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const removeTeacherFromModule = createAsyncThunk('teacher/removeTeacherFromModule', async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(`/api/teachers/${params.teacherId}/modules/${params.moduleId}`, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const addTeachersToModule = createAsyncThunk('teacher/addTeachersToModule', async (params, thunkAPI) => {
  try {
    const { data } = await axios.post(`/api/modules/${params.moduleId}/add-teachers`, {
      teachers: params.teachers
    }, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

const initialState = {
  allTeachers: [],

  teachers: [],
  isTeachersLoading: true,

  addTeachersToModuleDetails: {},

  createTeacherDetails: null,

  updateTeacherDetails: null
}

const teacherSlice = createSlice({
  name: 'teacher',
  initialState,
  reducers: {
    resetCreateTeacherDetails(state) {
      state.createTeacherDetails = null
    },
    resetUpdateTeacherDetails(state) {
      state.updateTeacherDetails = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllTeachers.fulfilled, (state, action) => {
        state.allTeachers = action.payload
      })
      .addCase(getTeachersByModule.fulfilled, (state, action) => {
        state.isTeachersLoading = false
        state.teachers = action.payload
      })
      .addCase(createTeacher.fulfilled, (state, action) => {
        state.teachers = [...state.teachers, action.payload]
        state.allTeachers = [...state.allTeachers, action.payload]
      })
      .addCase(createTeacher.rejected, (state, action) => {
        state.createTeacherDetails = action.payload
      })
      .addCase(updateTeacher.fulfilled, (state, action) => {
        const { teacherId } = action.meta.arg
        const teacherToReplaceIdx = state.teachers.findIndex(teacher => teacher.id === teacherId)
        state.teachers[teacherToReplaceIdx] = action.payload
      })
      .addCase(updateTeacher.rejected, (state, action) => {
        state.updateTeacherDetails = action.payload
      })
      .addCase(deleteTeacher.fulfilled, (state, action) => {
        const { teacherId } = action.meta.arg
        state.teachers = state.teachers.filter(teacher => teacher.id !== teacherId)
      })
      .addCase(removeTeacherFromModule.fulfilled, (state, action) => {
        const { teacherId } = action.meta.arg
        state.teachers = state.teachers.filter(teacher => teacher.id !== teacherId)
      })
      .addCase(addTeachersToModule.fulfilled, (state, action) => {
        state.addTeachersToModuleDetails = action.payload
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  }
})

export const { resetCreateTeacherDetails, resetUpdateTeacherDetails } = teacherSlice.actions
export default teacherSlice.reducer