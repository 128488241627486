import { combineReducers } from 'redux'
// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import groupLeader from './groupLeader'
import assignments from './assignments'
import forum from './forum'
import modules from './module'
import lessons from './lessons'
import events from './events'
import progress from './progress'
import group from './group'
import certificate from './certificate'
import statistics from './statistics'
import user from './user'
import reminder from './reminder'
import teacher from './teacher'
import language from './language'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  groupLeader,
  forum,
  modules,
  assignments,
  lessons,
  events,
  progress,
  group,
  certificate,
  statistics,
  user,
  reminder,
  teacher,
  language
})

export default rootReducer
