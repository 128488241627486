import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { handleLogout } from '@store/authentication'

export const STUDENTS_PER_PAGE = 10

export const getGroupsOverview = createAsyncThunk('groupLeader/getGroupsOverview', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/groups/groups-overview`, {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return response.data.groupOverview
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getMyGroups = createAsyncThunk('groupLeader/getMyGroups', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/groups/my-groups`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getGroupStudents = createAsyncThunk(
  "groupLeader/getGroupStudents",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `/api/users/students/${params.groupId}`,
        {
          params: {
            canHaveCertificate: true
          },
          headers: {
            Authorization: params.accessToken
          }
        }
      )
      if (response.status === 200) {
        return response.data
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getGroupOverview = createAsyncThunk('groupLeader/getGroupOverview', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/groups/details/${params.groupId}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const addStudentsToGroup = createAsyncThunk('groupLeader/addStudentsToGroup', async (params, thunkAPI) => {
  try {
    const response = await axios.post(
      `/api/groups/${params.groupId}/students/add`,
      {
        students: params.students,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )

    if (response.status === 200) {
      thunkAPI.dispatch(
        getGroupOverview({
          accessToken: params.accessToken,
          groupId: params.groupId,
        }),
      )
    }

    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const createGroup = createAsyncThunk('groupLeader/createGroup', async (params, thunkAPI) => {
  try {
    const response = await axios.post(
      `/api/groups`,
      {
        name: params.name,
        description: params.description,
        color: params.color,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )
    if (response.status === 201) {
      thunkAPI.dispatch(
        addStudentsToGroup({
          groupId: response.data.id,
          students: params.students,
        }),
      )
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getStudentsOverview = createAsyncThunk('groupLeader/getStudentsOverview', async (params, thunkAPI) => {
  try {
    let requestUrl = "/api/users/students-overview"
    const limitExists = params.limit !== undefined
    const offsetExists = params.offset !== undefined
    const searchExists = params.search !== undefined && params.search.length !== 0
    if (limitExists && offsetExists) {
      requestUrl = `${requestUrl}?limit=${params.limit}&offset=${params.offset}`

      if (searchExists) {
        requestUrl = `${requestUrl}&search=${params.search}`
      }
    } else if (searchExists) {
      requestUrl = `${requestUrl}?search=${params.search}`
    }
    const response = await axios.get(requestUrl, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const createStudent = createAsyncThunk('groupLeader/createStudent', async (params, thunkAPI) => {
  try {
    const response = await axios.post(
      `/api/users/invite`,
      {
        firstName: params.firstName,
        lastName: params.lastName,
        email: params.email,
        role: 'student',
        leader: params.groupLeader,
        groupId: params.groupId,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )
    if (response.status === 200) {
      await thunkAPI.dispatch(getStudentsOverview(params))
    }
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const deleteGroup = createAsyncThunk('groupLeader/deleteGroup', async (params, thunkAPI) => {
  try {
    const response = await axios.delete(`/api/groups/${params.id}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.status === 200) {
      return params.id
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getStudentsWithoutGroup = createAsyncThunk(
  'groupLeader/getStudentsWithoutGroup',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`/api/users/group-leader/students/without-group`, {
        headers: {
          Authorization: params.accessToken,
        },
      })
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message })
    }
  },
)

export const removeStudent = createAsyncThunk('groupLeader/removeStudent', async (params, thunkAPI) => {
  try {
    const response = await axios.post(
      `/api/groups/${params.groupId}/students/remove`,
      {
        students: params.students,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )
    if (response.status === 200) {
      thunkAPI.dispatch(
        getStudentsOverview({
          accessToken: params.accessToken,
          offset: 0,
          limit: STUDENTS_PER_PAGE
        }),
      )
      thunkAPI.dispatch(
        getGroupOverview({
          accessToken: params.accessToken,
          groupId: params.groupId,
        })
      )
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getGroupById = createAsyncThunk('groupLeader/getGroupById', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/groups/${params.groupId}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const sendReminderToGroup = createAsyncThunk('groupLeader/sendReminderToGroup', async (params, thunkAPI) => {
  try {
    const response = await axios.post(
      `/api/reminders/group/${params.groupId}`,
      {
        message: params.message,
        subject: params.subject,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const sendReminderToGroups = createAsyncThunk('groupLeader/sendReminderToGroups', async (params, thunkAPI) => {
  try {
    const response = await axios.post(
      `/api/reminders/groups/`,
      {
        message: params.message,
        subject: params.subject,
        groups: params.groups,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const sendReminderToStudent = createAsyncThunk('groupLeader/sendReminderToStudent', async (params, thunkAPI) => {
  try {
    const response = await axios.post(
      `/api/reminders/student/${params.studentId}`,
      {
        message: params.message,
        subject: params.subject,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getStudentDetails = createAsyncThunk('groupLeader/getStudentDetails', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/users/students-overview`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    if (response.data && response.data.records) {
      return response.data.records.find(student => student.id === params.studentId)
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const generateCertificate = createAsyncThunk('groupLeader/generateCertificate', async (params, thunkAPI) => {
  try {
    const response = await axios.post(
      `/api/certificates/${params.studentId}`,
      {
        groupId: params.groupId,
        studentName: params.studentName,
        studentEmail: params.studentEmail,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )

    return response.data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const sendCertificate = createAsyncThunk('groupLeader/sendCertificate', async (params, thunkAPI) => {
  try {
    const response = await axios.post(
      `/api/certificates/send-email/${params.studentId}`,
      {
        studentEmail: params.studentEmail,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )

    return response.data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const getCertificates = createAsyncThunk('groupLeader/getCertificates', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/all-certificates/${params.groupId}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return response.data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const deleteCertificate = createAsyncThunk('groupLeader/deleteCertificate', async (params, thunkAPI) => {
  try {
    const response = await axios.delete(`/api/certificates/${params.id}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return response.data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const getAllStudents = createAsyncThunk(
  'groupLeader/getAllStudents',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`/api/users/students`, {
        headers: {
          Authorization: params.accessToken,
        }
      })
  
      return response.data
    } catch (error) {
      const { errors } = error.response.data
      return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
    }
  }
)

const initialState = {
  dataCreateStudent: {},

  groupsOverview: [],
  isGroupsOverviewLoading: true,

  studentsWithoutGroup: [],
  isStudentsWithoutGroupLoading: true,

  groupDetails: null,
  isGroupDetailsLoading: true,

  myGroups: [],
  isMyGroupsLoading: true,

  groupOverview: [],
  isGroupOverviewLoading: true,

  groupStudents: [],
  isGroupStudentsLoading: true,

  studentsOverview: {
    records: []
  },
  isStudentsOverviewLoading: true,

  studentDetails: {},
  isStudentDetailsLoading: true,

  certificateDetails: {},

  sendCertificateDetails: {},

  certificatesDetails: {},
  isCertificatesDetailsLoading: true,
  isCertificatesDetailsError: false,

  deleteCertificateDetails: {},
  isDeleteCertificateDetailsLoading: true,
  isDeleteCertificateDetailsError: false,

  studentsPagination: {
    offset: 0,
    limit: STUDENTS_PER_PAGE,
    currentPage:0
  },

  allStudents: []
}

const groupLeaderSlice = createSlice({
  name: 'groupLeader',
  initialState,
  reducers: {
    resetCreateStudent(state) {
      state.dataCreateStudent = {}
    },
    resetGroupOverviewLoading(state) {
      state.isStudentsOverviewLoading = true
      state.isGroupsOverviewLoading = true
    },
    resetGroupStudents(state) {
      state.groupStudents = []
      state.isGroupStudentsLoading = true
    },
    resetCertificateDetails(state) {
      state.certificateDetails = {}
    },
    resetCertificatesDetails(state) {
      state.certificatesDetails = {}
      state.isCertificatesDetailsLoading = true
      state.isCertificatesDetailsError = false
    },
    resetSendCertificatesDetails(state) {
      state.sendCertificateDetails = {}
    },
    setCurrentStudentsPaginationPage(state, action) {
      state.studentsPagination.currentPage = action.payload
    }

  },
  extraReducers: builder => {
    builder
      .addCase(createStudent.fulfilled, (state, action) => {
        state.dataCreateStudent = action.payload
      })
      .addCase(createStudent.rejected, (state, action) => {
        state.dataCreateStudent = action.payload
      })
      .addCase(getGroupsOverview.fulfilled, (state, action) => {
        state.isGroupsOverviewLoading = false
        state.groupsOverview = action.payload
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.groupsOverview = state.groupsOverview.filter(group => group.id !== action.payload)
      })
      .addCase(getStudentsWithoutGroup.fulfilled, (state, action) => {
        state.isStudentsWithoutGroupLoading = false
        state.studentsWithoutGroup = action.payload
      })
      .addCase(getGroupById.fulfilled, (state, action) => {
        state.isGroupDetailsLoading = false
        state.groupDetails = action.payload
      })
      .addCase(getMyGroups.fulfilled, (state, action) => {
        state.isMyGroupsLoading = false
        state.myGroups = action.payload.records
      })
      .addCase(getGroupOverview.fulfilled, (state, action) => {
        state.isGroupOverviewLoading = false
        state.groupOverview = action.payload.groupDetails
      })
      .addCase(getGroupStudents.fulfilled, (state, action) => {
        state.isGroupStudentsLoading = false
        state.groupStudents = action.payload
      })
      .addCase(getStudentsOverview.fulfilled, (state, action) => {
        state.isStudentsOverviewLoading = false
        // let students = []
        // for (const student of action.payload) {
        //   if (student.name && student.email) {
        //     students.push(student)
        //   }
        // }

        // // remove duplicates
        // students = students.filter((value, index, self) => index === self.findIndex(t => t.id === value.id))
        state.studentsOverview = action.payload
      })
      .addCase(getStudentDetails.fulfilled, (state, action) => {
        state.isStudentDetailsLoading = false
        state.studentDetails = action.payload
      })
      .addCase(generateCertificate.fulfilled, (state, action) => {
        state.certificateDetails = action.payload
      })
      .addCase(generateCertificate.rejected, (state, action) => {
        state.certificateDetails = action.payload
      })
      .addCase(sendCertificate.fulfilled, (state, action) => {
        state.sendCertificateDetails = action.payload
      })
      .addCase(sendCertificate.rejected, (state, action) => {
        state.sendCertificateDetails = action.payload
      })
      .addCase(getCertificates.fulfilled, (state, action) => {
        state.isCertificatesDetailsLoading = false
        state.certificatesDetails = action.payload
      })
      .addCase(getCertificates.rejected, (state, action) => {
        state.isCertificatesDetailsLoading = false
        state.isCertificatesDetailsError = true
        state.certificatesDetails = action.payload
      })
      .addCase(deleteCertificate.fulfilled, (state, action) => {
        const certificateId = action.meta.arg.id
        state.isDeleteCertificateDetailsLoading = false
        state.deleteCertificateDetails = action.payload
        state.certificatesDetails = {
          ...state.certificateDetails,
          certificates: state.certificatesDetails.certificates.filter(c => c.id !== certificateId),
        }
      })
      .addCase(getAllStudents.fulfilled, (state, action) => {
        state.allStudents = action.payload
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  },
})

export const {
  resetCreateStudent,
  resetGroupStudents,
  resetCertificatesDetails,
  resetCertificateDetails,
  resetSendCertificatesDetails,
  resetGroupOverviewLoading,
  setCurrentStudentsPaginationPage
} = groupLeaderSlice.actions
export default groupLeaderSlice.reducer
