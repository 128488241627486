import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { ROUTES } from '../enum'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { getStudentsOverview, STUDENTS_PER_PAGE } from './groupLeader'
import { getGroupLeaders, getNetworkLeaders } from './user'
const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const changeNewsletterLanguage = createAsyncThunk('user/changeNewsletterLanguage', async (params, thunkAPI) => {
  try {
    const response = await axios.put(
      `/api/users/change-newsletter-language`,
      {
       language: params.language
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )
    const newUserData = {
      ...params.parsedUser,
      ...response.data,
    }
    return newUserData
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const updateUserDetails = createAsyncThunk('authentication/updateUserDetails', async (params, thunkAPI) => {
  try {
    const requestBody = {
      firstName: params.firstName,
      lastName: params.lastName,
    }
    const response = await axios.put(`/api/users`, requestBody, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    const newUserData = {
      ...params.parsedUser,
      ...response.data,
    }
    return newUserData
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const updateUserProfilePicture = createAsyncThunk(
  'authentication/updateUserProfilePicture',
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(`/api/users/profile-picture`, params.data, {
        headers: {
          Authorization: params.accessToken,
          'Content-Type': 'multipart/form-data',
        },
      })
      const newUserData = {
        ...params.parsedUser,
        ...response.data,
      }
      return newUserData
    } catch (error) {
      const { errors } = error.response.data
      return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
    }
  },
)

export const changePassword = createAsyncThunk('authentication/changePassword', async (params, thunkAPI) => {
  try {
    const requestBody = {
      currentPassword: params.currentPassword,
      newPassword: params.newPassword,
    }
    const response = await axios.put(`/api/users/password`, requestBody, {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return response.data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const changeEmail = createAsyncThunk('authentication/changeEmail', async (params, thunkAPI) => {
  try {
    const requestBody = {
      password: params.password,
      newEmail: params.newEmail,
    }
    const response = await axios.put(`/api/users/email`, requestBody, {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return response.data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const closeAccount = createAsyncThunk('authentication/closeAccount', async (params, thunkAPI) => {
  try {
    const response = await axios.post(`/api/users/close-account`, {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return response.data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const reactivateUserAccount = createAsyncThunk(
  'authentication/reactivateUserAccount',
  async (params, thunkAPI) => {
    try {
      const response = await axios.post(
        `/api/users/reactivate-account`,
        {
          id: params.id,
        },
        {
          headers: {
            Authorization: params.accessToken,
          },
        },
      )

      if (response.status === 200) {
        if (params.role) {
          switch (params.role) {
            case 'student': {
              const requestParams = {
                accessToken: params.accessToken,
                limit: STUDENTS_PER_PAGE,
                offset: 0,
              }
              await thunkAPI.dispatch(getStudentsOverview(requestParams))
              break
            }
            case 'my-group-leaders': {
              await thunkAPI.dispatch(getGroupLeaders({ accessToken: params.accessToken }))
              break
            }
            case 'my-network-leaders': {
              await thunkAPI.dispatch(getNetworkLeaders({ accessToken: params.accessToken, leaderId: params.leaderId }))
            }
          }
        }
      
      }

      return response.data
    } catch (error) {
      const { errors } = error.response.data
      return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
    }
  },
)

export const registerInterest = createAsyncThunk('authentication/registerInterest', async (params, thunkAPI) => {
  try {
    await axios.post(`/api/users/register-interest`, params, {
      headers: {
        Authorization: params.accessToken,
      },
    })

  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    errors: {
      changeEmail: null,
      changePassword: null,
      changeProfilePicture: null,
      changePersonalData: null,
      registerInterest: null,
    },
    notifications: {
      changePersonalDataSuccess: false,
      changeEmailSuccess: false,
      registerInterestSuccess: false,
    },
  },
  reducers: {
    clearError: (state, action) => {
      state.errors[action.payload] = null
    },
    clearNotifications: state => {
      state.notifications = {
        changePersonalDataSuccess: false,
        changeEmailSuccess: false,
        registerInterestSuccess: false
      }
    },
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
      localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
  },
  extraReducers: builder => {
    builder
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        // @TODO refactor to remove duplication
        state.userData = action.payload
        state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
        state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
        localStorage.setItem('userData', JSON.stringify(action.payload))
        localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
        localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)

        state.notifications.changePersonalDataSuccess = true
      })
      .addCase(updateUserProfilePicture.fulfilled, (state, action) => {
        state.userData = action.payload
        state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
        state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
        localStorage.setItem('userData', JSON.stringify(action.payload))
        localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
        localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)

        state.notifications.changePersonalDataSuccess = true
      })
      .addCase(closeAccount.fulfilled, state => {
        state.userData = {}
        state[config.storageTokenKeyName] = null
        state[config.storageRefreshTokenKeyName] = null
        localStorage.removeItem('userData')
        localStorage.removeItem(config.storageTokenKeyName)
        localStorage.removeItem(config.storageRefreshTokenKeyName)
        window.location.href = ROUTES.HOME
      })
      .addCase(changeEmail.rejected, (state, action) => {
        state.errors.changeEmail = action.payload.error
      })
      .addCase(changeEmail.fulfilled, state => {
        state.notifications.changeEmailSuccess = true
      })
      .addCase(changePassword.fulfilled, state => {
        state.userData = {}
        state[config.storageTokenKeyName] = null
        state[config.storageRefreshTokenKeyName] = null
        localStorage.removeItem('userData')
        localStorage.removeItem(config.storageTokenKeyName)
        localStorage.removeItem(config.storageRefreshTokenKeyName)
        window.location.href = ROUTES.LOGIN
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.errors.changePassword = action.payload.error
      })
      .addCase(updateUserProfilePicture.rejected, (state, action) => {
        state.errors.changeProfilePicture = action.payload.error
      })
      .addCase(updateUserDetails.rejected, (state, action) => {
        state.errors.changePersonalData = action.payload.error
      })
      .addCase(registerInterest.rejected, (state, action) => {
        state.errors.registerInterest = action.payload.error
        state.notifications.registerInterestSuccess = false
      })
      .addCase(registerInterest.fulfilled, state => {
        state.notifications.registerInterestSuccess = true
      })
      .addCase(changeNewsletterLanguage.fulfilled, (state, action) => {
        // @TODO refactor to remove duplication
        state.userData = action.payload
        state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
        state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
        localStorage.setItem('userData', JSON.stringify(action.payload))
        localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
        localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)

      })
  },
})

export const { handleLogin, handleLogout, handleUpdateProfile, handleUpdateEmail, clearError, clearNotifications } =
  authSlice.actions

export default authSlice.reducer
