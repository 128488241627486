import { LoadingSpinner } from '@src/common'
import ScreenLogo from '@src/assets/images/svg/screen-logo.svg'
import './LoadingScreen.scss'

const LoadingScreen = () => {
  return (
    <div className="loading-screen__wrapper">
      <img src={ScreenLogo} alt="screen-logo" />

      <LoadingSpinner hasLoadingText />
    </div>
  )
}

export default LoadingScreen
