import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { completeLesson } from './progress'

import { handleLogout } from '@store/authentication'

export const getAllModules = createAsyncThunk('modules/getAllModules', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/modules`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getModulesGroupsOverview = createAsyncThunk(
  'groupLeader/getModulesGroupsOverview',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`/api/groups/groups-overview`, {
        headers: {
          Authorization: params.accessToken,
        },
      })

      return response.data.groupOverview
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message })
    }
  },
)

export const getModulesStudentsOverview = createAsyncThunk(
  'groupLeader/getModulesStudentsOverview',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`/api/users/students-overview`, {
        headers: {
          Authorization: params.accessToken,
        },
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message })
    }
  },
)

export const getModuleById = createAsyncThunk('modules/getModulesById', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/modules`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data.find(module => module.id === params.moduleId)
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const assignModulesToStudent = createAsyncThunk('modules/assignModulesToStudent', async (params, thunkAPI) => {
  try {
    const response = await axios.post(
      `/api/modules/assign/student/${params.studentId}`,
      {
        modules: params.modules,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )
    await thunkAPI.dispatch(getModulesGroupsOverview(params))
    await thunkAPI.dispatch(getModulesStudentsOverview(params))
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const assignModulesToGroup = createAsyncThunk('modules/assignModulesToGroup', async (params, thunkAPI) => {
  try {
    const response = await axios.post(
      `/api/modules/assign/group/${params.groupId}`,
      {
        modules: params.modules,
      },
      {
        headers: {
          Authorization: params.accessToken,
        },
      },
    )
    await thunkAPI.dispatch(getModulesGroupsOverview(params))
    await thunkAPI.dispatch(getModulesStudentsOverview(params))
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getResourcesByModuleId = createAsyncThunk('modules/getResourcesByModuleId', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/modules/${params.moduleId}/resources`, {
      headers: {
        Authorization: params.accessToken,
      },
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const createModuleAssignment = createAsyncThunk('modules/createModuleAssignment', async (params, thunkAPI) => {
  const { payload } = await thunkAPI.dispatch(completeLesson({ accessToken: params.accessToken, lessonId: params.moduleId, moduleId: params.moduleId }))

  if (!payload?.error) {
    try {
      const formData = new FormData()
      formData.append('answer', params.answer)
      formData.append('module', params.moduleId)
      formData.append('groupLeader', params.groupLeader || params.studentId)
      params.resources.forEach(resource => {
        formData.append('resources', resource)
      })
  
      const response = await axios.post(`/api/module-assigments`, formData, {
        headers: {
          Authorization: params.accessToken,
        },
      })
  
      return response.data
    } catch (error) {
      const { errors } = error.response.data
      return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
    }
  }
  
  return thunkAPI.rejectWithValue({ error: payload?.error })
})

export const startModule = createAsyncThunk('modules/startModule', async (params, thunkAPI) => {
  try {
    const response = await axios.post(`/api/groups/${params.groupId}/start-module/${params.moduleId}`, {
      headers: {
        Authorization: params.accessToken
      },
    })

    return response.data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const createModule = createAsyncThunk('modules/createModule', async (params, thunkAPI) => {
  const formData = new FormData()
  formData.append("title", params.title)
  formData.append("coverImage", params.file)
  formData.append("translations", JSON.stringify(params.translations) || null)

  try {
    const { data } = await axios.post(`/api/modules`, formData, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const updateModule = createAsyncThunk('modules/updateModule', async (params, thunkAPI) => {
  const formData = new FormData()
  formData.append("title", params.title || '')
  formData.append("coverImage", params.file || '')
  formData.append("content", params.content || '')
  formData.append("assignmentTitle", params.assignmentTitle || '')
  formData.append("assignmentDescription", params.assignmentDescription || '')
  formData.append("translations", JSON.stringify(params.translations) || null)

  try {
    const { data } = await axios.put(`/api/modules/${params.moduleId}`, formData, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

export const deleteModule = createAsyncThunk('modules/deleteModule', async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(`/api/modules/${params.moduleId}`, {
      headers: {
        Authorization: params.accessToken
      }
    })

    return data
  } catch (error) {
    const { errors } = error.response.data
    return thunkAPI.rejectWithValue({ error: errors.length ? errors[0].message : error.message })
  }
})

const initialState = {
  allModules: [],
  isAllModulesLoading: true,

  moduleOverview: null,
  isModuleOverviewLoading: true,

  lessons: [],
  isLessonsLoading: true,

  groupsOverview: [],
  isGroupsOverviewLoading: true,

  studentsOverview: [],
  isStudentsOverviewLoading: true,

  resources: [],
  isResourcesLoading: true,

  moduleAssignment: {},

  dataAssignModulesToGroup: {},
  errorAssignModulesToGroup: {},

  dataModuleStart: {},
  errorModuleStart: {}
}

const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    resetModuleOverviewLoading(state) {
      state.isModuleOverviewLoading = true
    },
    resetModuleAssignment(state) {
      state.moduleAssignment = {}
    },
    resetModuleOverview(state) {
      state.moduleOverview = null
    },
    resetModules(state) {
      state.allModules = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllModules.fulfilled, (state, action) => {
        state.isAllModulesLoading = false
        state.allModules = action.payload
      })
      .addCase(getModuleById.fulfilled, (state, action) => {
        state.isModuleOverviewLoading = false
        state.moduleOverview = action.payload
      })
      .addCase(getModulesGroupsOverview.fulfilled, (state, action) => {
        state.isGroupsOverviewLoading = false
        state.groupsOverview = action.payload
      })
      .addCase(getModulesStudentsOverview.fulfilled, (state, action) => {
        state.isStudentsOverviewLoading = false
        let students = []
        action.payload.map(student => {
          if (student.name && student.email) {
            students.push(student)
          }
        })
        // remove duplicates
        students = students.filter((value, index, self) => index === self.findIndex(t => t.id === value.id))
        state.studentsOverview = students
      })
      .addCase(getResourcesByModuleId.fulfilled, (state, action) => {
        state.isResourcesLoading = false
        state.resources = action.payload
      })
      .addCase(createModuleAssignment.fulfilled, (state, action) => {
        state.moduleAssignment = action.payload
      })
      .addCase(createModuleAssignment.rejected, (state, action) => {
        state.moduleAssignment = action.payload
      })
      .addCase(assignModulesToGroup.fulfilled, (state, action) => {
        state.assignModulesToGroup = action.payload
      })
      .addCase(assignModulesToGroup.rejected, (state, action) => {
        state.errorAssignModulesToGroup = action.payload
      })
      .addCase(startModule.fulfilled, (state, action) => {
        state.dataModuleStart = action.payload
      })
      .addCase(startModule.rejected, (state, action) => {
        state.errorModuleStart = action.payload
      })
      .addCase(createModule.fulfilled, (state, action) => {
        state.allModules = [...state.allModules, action.payload]
      })
      .addCase(deleteModule.fulfilled, (state, action) => {
        const { moduleId } = action.meta.arg
        state.allModules = state.allModules.filter(module => module.id !== moduleId)
      })
      .addCase(updateModule.fulfilled, (state, action) => {
        const { moduleId } = action.meta.arg
        const moduleToReplaceIdx = state.allModules.findIndex(module => module.id === moduleId)
        state.allModules[moduleToReplaceIdx] = action.payload
        state.moduleOverview = action.payload
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  }
})

export const { resetModuleAssignment, resetModuleOverview, resetModuleOverviewLoading, resetModules } = modulesSlice.actions
export default modulesSlice.reducer
