import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { handleLogout } from '@store/authentication'

export const getAdminStatistics = createAsyncThunk('analytics/getAdminStatistics', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get('/api/statistics/admin', {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getAdminStatisticsOverall = createAsyncThunk('analytics/getAdminStatisticsOverall', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get('/api/statistics/admin/overall', {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getAdminGroupStatistics = createAsyncThunk("analytics/getAdminGroupStatistics", async (params, thunkAPI) => {
  try {
    const { data } = await axios.get(`/api/statistics/network-leaders/${params.networkLeaderId}`)

    return data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getNetworkLeaderStatistics = createAsyncThunk(
  'analytics/getNetworkLeaderStatistics',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get('/api/statistics/network-leader', {
        headers: {
          Authorization: params.accessToken,
        },
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message })
    }
  },
)

export const getNLGroupStatistics = createAsyncThunk('analytics/getNLGroupStatistics', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/statistics/group-leaders/${params.groupLeaderId}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getGroupStatistics = createAsyncThunk('analytics/getGroupStatistics', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/statistics/group/${params.groupId}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

export const getStudentStatistics = createAsyncThunk('analytics/getStudentStatistics', async (params, thunkAPI) => {
  try {
    const response = await axios.get(`/api/statistics/group/${params.groupId}/student/${params.studentId}`, {
      headers: {
        Authorization: params.accessToken,
      },
    })

    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message })
  }
})

const initialState = {
  loadingGroupStatistics: true,
  dataGroupStatistics: {},
  errorGroupStatistics: {},

  loadingStudentStatistics: true,
  dataStudentStatistics: {},
  errorStudentStatistics: {},

  loadingNetworkLeadersStatistics: true,
  dataNetworkLeadersStatistics: {},
  errorNetworkLeadersStatistics: {},

  loadingNLGroupStatistics: true,
  dataNLGroupStatistics: {},
  errorNLGroupStatistics: {},

  loadingAdminStatistics: true,
  dataAdminStatistics: {},
  errorAdminStatistics: {},

  loadingAdminStatisticsOverall: true,
  dataAdminStatisticsOverall: {},
  errorAdminStatisticsOverall: {},

  loadingAdminGroupStatistics: true,
  dataAdminGroupStatistics: {},
  errorAdminGroupStatistics: {}
}

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    resetGLStatisticsLoading(state) {
      state.loadingGroupStatistics = true
      state.loadingStudentStatistics = true
    },
    resetNLStatisticsLoading(state) {
      state.loadingNetworkLeadersStatistics = true
      state.loadingNLGroupStatistics = true
    },
    resetAdminStatisticsLoading(state) {
      state.loadingAdminStatistics = true
      state.loadingAdminGroupStatistics = true
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getGroupStatistics.fulfilled, (state, action) => {
        state.dataGroupStatistics = action.payload
        state.loadingGroupStatistics = false
      })
      .addCase(getGroupStatistics.rejected, (state, action) => {
        state.errorGroupStatistics = action.payload
        state.loadingGroupStatistics = false
      })
      .addCase(getStudentStatistics.fulfilled, (state, action) => {
        state.dataStudentStatistics = action.payload
        state.loadingStudentStatistics = false
      })
      .addCase(getStudentStatistics.rejected, (state, action) => {
        state.errorStudentStatistics = action.payload
        state.loadingStudentStatistics = false
      })
      .addCase(getNetworkLeaderStatistics.fulfilled, (state, action) => {
        state.dataNetworkLeadersStatistics = action.payload
        state.loadingNetworkLeadersStatistics = false
      })
      .addCase(getNetworkLeaderStatistics.rejected, (state, action) => {
        state.errorNetworkLeadersStatistics = action.payload
        state.loadingNetworkLeadersStatistics = false
      })
      .addCase(getNLGroupStatistics.fulfilled, (state, action) => {
        state.dataNLGroupStatistics = action.payload
        state.loadingNLGroupStatistics = false
      })
      .addCase(getNLGroupStatistics.rejected, (state, action) => {
        state.errorNLGroupStatistics = action.payload
        state.loadingNLGroupStatistics = false
      })
      .addCase(getAdminStatistics.fulfilled, (state, action) => {
        state.dataAdminStatistics = action.payload
        state.loadingAdminStatistics = false
      })
      .addCase(getAdminStatistics.rejected, (state, action) => {
        state.errorAdminStatistics = action.payload
        state.loadingAdminStatistics = false
      })
      .addCase(getAdminStatisticsOverall.fulfilled, (state, action) => {
        state.dataAdminStatisticsOverall = action.payload
        state.loadingAdminStatisticsOverall = false
      })
      .addCase(getAdminStatisticsOverall.rejected, (state, action) => {
        state.errorAdminStatisticsOverall = action.payload
        state.loadingAdminStatisticsOverall = false
      })
      .addCase(getAdminGroupStatistics.fulfilled, (state, action) => {
        state.dataAdminGroupStatistics = action.payload
        state.loadingAdminGroupStatistics = false
      })
      .addCase(getAdminGroupStatistics.rejected, (state, action) => {
        state.dataAdminGroupStatistics = action.payload
        state.loadingAdminGroupStatistics = false
      })
      .addCase(handleLogout, () => {
        return initialState
      })
  },
})

export const { resetNLStatisticsLoading, resetGLStatisticsLoading, resetAdminStatisticsLoading } = statisticsSlice.actions
export default statisticsSlice.reducer
